import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { fromJS, List } from 'immutable';

import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import ChatContent from './ChatContent';
import ws from '../../../../ws';
import notifSystem from '../../../../notifSystem';
import api from '../../../../api';
import {
  WHATSUP,
  WHATSAPPBUSINESS
} from '@lba-dev/package.local-globals/smsMode';

const styles = (theme) => ({
  centeredDiv: {
    width: '100%',
    minHeight: 350,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  span: {
    fontSize: 12,
    minHeight: 20,
    alignItems: 'center',
  },
  toolbar: {
    marginBottom: 2,
    border: '1px solid rgba(0,0,0,0.125)',
    borderRadius: 5,
    padding: '8px 16px'
  },
  chatDiv: {
    height: 'calc(100% - 80px)',
    maxHeight: 'unset',
    overflow: 'hidden',
    '& .suggestionContainer': {
      bottom: 82,
      width: '100%',
      position: 'absolute',
      overflow: 'auto hidden'
    },
    '& .suggestionItem': {
      whiteSpace: 'normal'
    }
  },
  chatContent: {
    height: 'calc(100% - 135px)',
    overflow: 'hidden auto',
    position: 'relative'
  },
  chatElement: {
    maxWidth: 1024,
    height: '100%',
    margin: 'auto',
  },
  chatInputDiv: {
    maxWidth: 1024,
    paddingLeft: 16,
    paddingRight: 16,
    margin: 'auto',
    position: 'relative',
  },
  loadingBox: {
    position: 'absolute',
    margin: 'auto',
    top: 10,
    right: 0,
    left: 0,
    width: 30,
    height: 30,
    backgroundColor: '#fff',
    boxShadow: theme.shadows[1],
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  loadingSkeleton: {
    height: 90
  },
  overflow: {
    height: '100%',
    overflowY: 'auto'
  }
});
const mapStateToProps = ({ users, userId }) => ({
  user: users.find(u => u._id === userId)
});

const genFilter = (filter, name) => ({
  ...filter,
  ...(['intervention', 'devis'].includes(name) ?
    {
      artisan: { $exists: false },
      prosp: { $exists: false },
    } : {})
});

class ChatContainer extends PureComponent {
  state = {
    display: 15,
    page: 0,
    data: new List(),
    id: this.props.id,
    name: this.props.name,
    filters: this.props.filters,
    canGetMore: true,
    error: null,
    loading: false,
    hasWhatsapp: false,
    newContact: false,
  };

  setData = data => {
    if (data) {
      this.setState({
        data,
      });
    } else {
      notifSystem.error('Erreur', 'Données SMS non récupérées');
    }
  };

  updateSmsSeen = (lastsms) => {
    const {
      user, setLastMessages = e => e, smsType
    } = this.props;
    const { name, id } = this.state;
    const seen = (lastsms.seenBy || [])
      .find(e => !e.sender && e.login === user.login);
    if (!seen) {
      api.sms
        .custom('updateSeen')
        .post({ filter: { [name]: id } })
        .then(() => {
          setLastMessages((oldMessages) => {
            const smsInd = oldMessages[smsType]
              .findIndex(e => e._id === lastsms[name]);
            if (~smsInd) {
              const elem = oldMessages[smsType][smsInd];
              elem.unSeenCount = 0;
              oldMessages[smsType][smsInd] = elem;
              return { ...oldMessages, name: [...oldMessages[smsType], elem] };
            }
            return oldMessages;
          });
        });
    }
  }

  loadData = filter => {
    const {
      name, color, user, newContact, hasWhatsapp: pHasWS, freshContact
    } = this.props;
    const { data, display, page } = this.state;
    this.setState({ loading: true });
    api.sms
      .getAll({
        query: JSON.stringify(genFilter(filter, name)),
        display,
        page,
        sort: { date: -1 },
      })
      .then(res => {
        const array = res.body().map(e => e.data());
        const newData = fromJS(array
          .map(e => ({
            ...e,
            color: !e.sender && color,
            expanded: !e.sender ||
              e.messageType === 'chat' ||
              !e.messageType ||
             (e.transmitter === user.login && !e.isAuto)
          })));
        if (array.length > 0) {
          this.updateSmsSeen(array[0]);
        }
        const list = new List([...newData.reverse(), ...data]);
        this.setState({
          data: list,
          canGetMore: newData.size === display,
          page,
          hasWhatsapp: freshContact ? pHasWS :
            list.some(e => [WHATSAPPBUSINESS, WHATSUP].includes(e.get('mode'))),
          newContact: newContact || list.some(e => e.get('newContact')),
          error: null,
        });
      })
      .catch(error => {
        this.setState({
          data: new List(),
          canGetMore: false,
          error: !newContact && error,
          newContact,
          hasWhatsapp: pHasWS
        });
      })
      .finally(() => this.setState({ loading: false }));
  };

  componentDidMount() {
    this.getData(this.props);
    ws.on('update_sms', this.wsUpdate);
  }

  componentWillUnmount() {
    ws.removeEventListener('update_sms', this.wsUpdate);
  }
  getData = () => {
    const { filters, freshContact } = this.props;
    const { id, name } = this.state;
    if (id && name) {
      this.loadData(freshContact ? filters : {
        [name]: id
      });
    }
  };

  wsUpdate = obj => {
    const { color, user } = this.props;
    const { id, data, name } = this.state;
    if (obj.elemAdded) {
      return;
    }
    if (obj && color) {
      obj.color = color;
    }
    obj.expanded = !obj.sender || obj.messageType === 'chat' ||
      !obj.messageType ||
      (obj.transmitter === user.login && !obj.isAuto);
    if (obj && obj[name] === id && (
      (name !== 'artisan' && !obj.artisan) || name === 'artisan')) {
      this.setState({
        display: 15,
        page: 0,
        data: data.last()?.get('_id') === obj._id
          ? data.pop().push(fromJS(obj))
          : data.push(fromJS(obj)),
      });
    }
  };

  componentDidUpdate(props) {
    if (this.props.id !== props.id) {
      this.setState(
        {
          display: 15,
          page: 0,
          id: this.props.id,
          name: this.props.name,
          filters: this.props.filters,
          data: new List(),
        },
        () => this.getData()
      );
    }
  }

  getMoreMessage = () => {
    this.setState({
      page: this.state.page + 1,
    }, this.getData);
  }

  handleExpand = (messageId) => {
    this.setState(({ data }) => ({
      data: fromJS([...data.toJS()].map(e => ({
        ...e,
        ...(e._id === messageId ?
          { expanded: !e.expanded } : {})
      })))
    }));
  }

  render() {
    const { classes, selectedNumber, dataObject = {} } = this.props;
    const {
      id, name, index, page, data, error,
      canGetMore, loading, filters, hasWhatsapp, newContact
    } = this.state;
    return <>
      {(!newContact && error) || (!data.size && loading) ?
        <Grid container justifyContent="center" alignItems="center">
          <Grid item className={classes.centeredDiv}>
            {loading ? <CircularProgress size={70} /> : ''}
            <Typography variant="h6" style={{ color: error ? 'red' : '' }}>
              {error ? error.message : 'Chargement en cours...' }
            </Typography>
          </Grid>
        </Grid> : <ChatContent
          key={2}
          {...{
            data,
            name,
            id,
            index,
            classes,
            setData: this.setData,
            getMoreMessage: this.getMoreMessage,
            handleExpand: this.handleExpand,
            canGetMore,
            page,
            filters,
            hasWhatsapp,
            loading,
            newContact,
            dataObject,
            selectedNumber
          }}
        />}
    </>;
  }
}

export default connect(mapStateToProps, null)(
  withStyles(ChatContainer, styles));
